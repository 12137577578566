import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'

import { useAuth } from '../auth/contexts/AuthContext'
import { getCaseName } from '../reducers/caseSlice'
import { setDebugActive, getDebugActive } from '../reducers/debugSlice'
import { DashboardBanner } from '../Dashboard'
import { getCaseData, getSettings } from '../../utils'

import menuIcon from '../../images/menu-icon.jpg'
import mapIcon from '../../images/menu-map-icon.jpg'
import profileIcon from '../../images/menu-profile-icon.jpg'
import scriptIcon from '../../images/menu-script-icon.jpg'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: 'absolute',
      zIndex: 50,
      width: '100vw',
      display: 'flex',
      flexDirection: 'row-reverse',
      right: 0,
      '& a': {
        display: 'block',
      },
    },
    items: {
      display: 'flex',
      flexDirection: 'revert',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingLeft: '5vw',
      paddingRight: '5vw',
      paddingTop: 0,
      marginBottom: '1vw',
      '& a': {
        textTransform: 'uppercase',
        fontFamily: 'serif',
        textDecoration: 'none',
        fontSize: '1.2em',
        // height: 27,
        marginTop: 5,
      },
    },
    menuButton: {
      backgroundColor: 'rgb(0 0 0 / .9) !important',
      // margin: '1vw',
      border: 'solid #000 2px',
      height: 40,
      width: 40,
      '& img': {
        width: '100%',
        // height: '100%'
      },
    },
    menuIcon: {
      height: 35,
      width: 35,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '5px 10px',
    },
  }
})

const defaultMenuItems = (
  <>
    {/* <Link to="/">Home</Link>
        <Link to="/about">About</Link> */}
  </>
)

const Debug = () => {
  const active = useSelector(getDebugActive)
  const dispatch = useDispatch()
  const toggleDebug = (active) => {
    dispatch(setDebugActive(!active))
  }
  return (
    <a onClick={() => toggleDebug(active)}>Debug: {active ? 'On' : 'Off'}</a>
  )
}

const Menu = () => {
  const { classes } = useStyles()
  const { currentUser, userHasCase } = useAuth()
  const history = useHistory()
  const debugActive = useSelector(getDebugActive)
  const caseName = useSelector(getCaseName)
  const [items, setItems] = useState(defaultMenuItems)
  const [showMenu, setShowMenu] = useState(false)
  const [settings, setSettings] = useState()
  const [caseData, setCaseData] = useState()
  const location = useLocation()

  useEffect(() => {
    if (currentUser && caseName) {
      setItems(
        <>
          {currentUser.displayName}
          {defaultMenuItems}
          <Link to="/dashboard" style={{ display: showMenu ? 'flex' : 'none' }}>
            <img src={mapIcon} className={classes.menuIcon} alt="Map" />
          </Link>
          <Link to="/profile" style={{ display: showMenu ? 'flex' : 'none' }}>
            <img src={profileIcon} className={classes.menuIcon} alt="Profile" />
          </Link>
          {debugActive ? (
            <Link to="/update-profile">UpdateProfile</Link>
          ) : (
            <></>
          )}
          <Link to="/script" style={{ display: showMenu ? 'flex' : 'none' }}>
            <img src={scriptIcon} className={classes.menuIcon} alt="Script" />
          </Link>
          {settings?.developmentMode && <Debug />}
        </>
      )
    } else {
      setItems(
        <>
          {defaultMenuItems}
          {/* Alpha Hide: */}
          {/* Alpha Delete: */}
          {/* <Link to="/signup" className='alpha-delete'>Sign Up</Link>
                    <Link to="/login" className='alpha-delete'>Login</Link>
                    <Link to="/forgot-password" className='alpha-delete'>ForgotPassword</Link> */}
        </>
      )
    }
    loadSettings()
    loadCaseData()
  }, [currentUser, caseName, debugActive, showMenu])

  const loadSettings = async () => {
    const settings = await getSettings()
    setSettings(settings)
  }

  const loadCaseData = async () => {
    const caseData = await getCaseData()
    setCaseData(caseData)
  }

  const toggleMenu = () => {
    const hideMenu = () => {
      setShowMenu(false)
      unlisten()
    }
    const unlisten = history.listen((location, action) => {
      hideMenu()
    })
    setShowMenu(!showMenu)
  }

  if (
    !currentUser ||
    !caseName ||
    caseName === '' ||
    location.pathname === '/case/eq' ||
    location.pathname === '/welcome' ||
    location.pathname === '/'
  ) {
    return <></>
  }

  const rootShowMenu = {
    backgroundColor: showMenu ? 'rgb(0 0 0/.8)' : 'transparent',
  }

  return (
    <div
      id="menu-button-container"
      className={clsx('component', classes.root)}
      style={rootShowMenu}
    >
      {/* <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Button
          variant="contained"
          onClick={() => toggleMenu()}
          className={classes.menuButton}
          sx={{
            width: 15,
            height: 30,
            padding: 0,
            minWidth: 30,
            border: 'solid #fff 1px',
            borderRadius: 0,
          }}
        >
          <img src={menuIcon} alt="N" />
        </Button>
      </div>

      <div
        className={classes.items}
        style={{ display: showMenu ? 'flex' : 'none' }}
      >
        {!caseData?.menu?.hideBanner && <DashboardBanner />}
        {items}
      </div> */}
      <div
        className={classes.buttonContainer}
        // style={{ flexDirection: !showMenu ? 'row-reverse' : 'row' }}
      >
        <Button
          variant="contained"
          onClick={() => toggleMenu()}
          className={classes.menuButton}
          sx={{
            width: 15,
            height: 30,
            padding: 0,
            minWidth: 30,
            border: 'solid #fff 1px',
            borderRadius: 0,
          }}
        >
          <img src={menuIcon} alt="N" />
        </Button>
        {items}
        {/* {caseData?.menu?.hideBanner === true ? null : <DashboardBanner />} */}
      </div>
    </div>
  )
}

export default Menu
