import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getScene } from '../reducers/sceneSlice'
import { setPuzzleStatus, addItemToPath, getPath } from '../../utils'
import Content from '../Content'
import Breadcrumbs from '../Breadcrumbs'
import { ButtonNoir } from '../library'

interface SimpleGuessType {
  id: string
  pathId?: string
  solutions: string[]
  successMsg?: string
  failureMsg?: string
  caseSensitive?: boolean
  spaceSensitive?: boolean
}

const SimpleGuess = ({
  id,
  pathId,
  solutions,
  successMsg,
  failureMsg = 'is incorrect. Try again.',
  caseSensitive,
  spaceSensitive,
}: SimpleGuessType) => {
  const scene = useSelector(getScene)
  const puzzleScene =
    scene?.requirements && scene?.requirements.length
      ? scene.requirements.find((req: any) => req.id === id)
      : null
  const [guess, setGuess] = useState<string>('')
  const [msg, setMsg] = useState<string | undefined>()
  const [userSovledPuzzle, setUserSolvedPuzzle] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPuzzleStatus({ id: id, status: 'visited' })
  }, [])

  const massageValue = (value: string) => {
    let massagedValue = value
    if (!spaceSensitive) {
      massagedValue = massagedValue.replaceAll(' ', '')
    }
    if (!caseSensitive) {
      massagedValue = massagedValue.toLowerCase()
    }
    return massagedValue
  }

  const handleGuess = () => {
    setLoading(true)
    solutions.forEach(async (solution: string, index: number) => {
      const massagedSolution = massageValue(solution)
      const massagedGuess = massageValue(guess)
      if (massagedGuess === massagedSolution) {
        // correct - guess matches at least one solution
        if (pathId) {
          await addItemToPath(pathId)
        }
        setPuzzleStatus({ id: id })
        setUserSolvedPuzzle(true)
        setMsg(`Correct. ${successMsg ? successMsg : ''}`)
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } else if (index >= solutions.length - 1 && !userSovledPuzzle) {
        // incorrect - guess does not match any solutions
        setPuzzleStatus({ id: id, status: 'incorrect' })
        setGuess('')
        setMsg(`'${guess}' ${failureMsg}`)
        setLoading(false)
      }
    })
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleGuess()
    }
  }

  if (userSovledPuzzle) {
    return <div className="component">{msg}</div>
  }

  return (
    <div className="component">
      {puzzleScene ? <Content data={puzzleScene.content} /> : null}
      <div>{msg}</div>
      {!userSovledPuzzle ? (
        <input
          type="text"
          value={guess}
          onChange={(event) => setGuess(event.target.value)}
          onKeyPress={handleKeyPress}
        ></input>
      ) : null}
      <ButtonNoir onClick={handleGuess.bind(this)} loading={loading}>
        Guess
      </ButtonNoir>
    </div>
  )
}

export default SimpleGuess
