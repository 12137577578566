import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import { getPath, getCaseData } from '../../utils'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      fontFamily: 'serif',
      fontSize: 24,
    },
  }
})

function haveMatchingItems<T>(arr1: T[], arr2: T[]): boolean {
  // console.log('zzz arr1', arr1)
  // console.log('zzz arr2', arr2)

  // Sort both arrays
  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  // Use a two-pointer approach to check for matching items
  let i = 0,
    j = 0

  while (i < sortedArr1.length && j < sortedArr2.length) {
    if (
      sortedArr1[i] === sortedArr2[j] &&
      sortedArr1.length === sortedArr2.length
    ) {
      return true // Found a match
    } else if (sortedArr1[i] < sortedArr2[j]) {
      i++
    } else {
      j++
    }
  }

  return false // No matching items
}

const Hint = () => {
  const { classes } = useStyles()
  const [path, setPath] = useState<any>()
  const [hints, setHints] = useState<any>()
  const [hint, setHint] = useState<any>()

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (!hints?.length || !path?.length) {
      return
    }

    const hint: any = [...hints].filter((hint) =>
      haveMatchingItems(hint.path, [...path])
    )

    if (hint?.[0] && hint?.length) {
      setHint(hint[0].hint)
    }
  }, [path, hints])

  const loadData = async () => {
    const pathResponse: string[] = await getPath()
    const caseDataResponse = await getCaseData()
    setPath(pathResponse)
    setHints(caseDataResponse?.hints)
  }

  return <div className={classes.root}>{hint}</div>
}

export default Hint
