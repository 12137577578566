import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

import { getScene } from '../../reducers/sceneSlice'
import { setPuzzleStatus } from '../../../utils'
import { MediaImage, MediaVideo } from '../../Media'
import Content from '../../Content'
import { ButtonNoir } from '../../library'

const reqId = 'the-goodbye-puzzle'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      fontFamily: 'serif',
      fontSize: 24,
      textAlign: 'center',
      input: {
        padding: 10,
        fontSize: 16,
      },
    },
  }
})

const TheGoodbye = () => {
  const { classes } = useStyles()
  const scene = useSelector(getScene)
  const requirements = scene.requirements.find((req: any) => req.id === reqId)
  const [guess, setGuess] = useState<string>('')
  const [msg, setMsg] = useState<string | undefined>()
  const [userSovledPuzzle, setUserSolvedPuzzle] = useState(false)

  useEffect(() => {
    setPuzzleStatus({ id: reqId, status: 'visited' })
  }, [])

  const handleGuess = () => {
    if (guess?.toString() === '1979') {
      setPuzzleStatus({ id: reqId })
      setMsg(`Correct`)
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } else {
      setPuzzleStatus({ id: reqId, status: 'incorrect' })
      setGuess('')
      setMsg(`'${guess}' is incorrect. Try again.`)
    }
  }

  if (userSovledPuzzle) {
    return <div className="component">Correct</div>
  }

  return (
    <div className={clsx('component', classes.root)}>
      {/* <h2>{reqId} (123)</h2> */}
      <Content data={requirements.content} />
      <div>{msg}</div>
      {!userSovledPuzzle ? (
        <input
          type="text"
          value={guess}
          onChange={(event) => setGuess(event.target.value)}
          maxLength={5}
        ></input>
      ) : null}
      <ButtonNoir onClick={handleGuess.bind(this)}>Guess</ButtonNoir>
    </div>
  )
}

export default TheGoodbye
