// src/redux/postsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../axios' // Import the axios instance you created

// Create an async thunk to fetch data
export const fetchPosts = createAsyncThunk('sample/x', async () => {
  console.log('xxx here')
  const response = await axiosInstance.get('/sample') // Replace with your API endpoint
  console.log('xxx response', response)
  return response.data // Return the data from the response
})

// Create a slice
const sampleSlice = createSlice({
  name: 'sample',
  initialState: {
    response: <any>'',
    loading: false,
    error: <string | undefined>undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false
        state.response = action.payload // Set fetched posts to state
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message // Handle error
      })
  },
})

// Export the reducer to be used in the store
export default sampleSlice.reducer
