import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'

import scrollDownImgPng from '../../images/scroll-down-arrow.png'

const useStyles = makeStyles()((theme) => {
  return {
    img: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      height: 75,
      width: 75,
      position: 'absolute',
      top: '550px',
      left: '40%',
      'z-index': '99',
      opacity: '50%',
    },
  }
})

const ScrollDownHint = () => {
  const { classes } = useStyles()
  const [hasScrolled, setHasScrolled] = useState(false)
  const [showHint, setShowHint] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(true)
      window.removeEventListener('scroll', handleScroll) // Remove the event listener after scroll
    }

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Set a timeout to check if the user hasn't scrolled in 5 seconds
    const scrollTimeout = setTimeout(() => {
      if (!hasScrolled) {
        console.log("User hasn't scrolled within 5 seconds.")
        setShowHint(true)
      }
    }, 5000)

    // Cleanup the timeout and event listener when the component unmounts
    return () => {
      clearTimeout(scrollTimeout)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])

  return (
    <div>
      {showHint && !hasScrolled && (
        <img src={scrollDownImgPng} className={classes.img} />
      )}
    </div>
  )
}

export default ScrollDownHint
