import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import 'react-slideshow-image/dist/styles.css'
import '../sass/index.scss'

import { AuthProvider } from './auth/contexts/AuthContext'
import { Login, ForgotPassword, Signup } from './auth'
import Container from './Container'
import About from './About'
import Home from './Home'
import CaseIntro from './CaseIntro'
import Welcome from './Welcome'
import PrivateRoutes from './PrivateRoutes'
import { getUserID } from './reducers/userSlice'
import { getCaseName } from './reducers/caseSlice'
import settings from '../settings.json'
import { ProgressNoir } from './library'

const App = () => {
  const userId = useSelector(getUserID)
  const caseName = useSelector(getCaseName)

  const loadStyle = async () => {
    try {
      // Dynamically import the SCSS file
      const stylesFolder = settings?.stylesFolder
      if (stylesFolder) {
        await import(`../sass/cases/${stylesFolder}/index.scss`)
      }
    } catch (error) {
      console.error('Error loading theme styles:', error)
    }
  }

  useEffect(() => {
    loadStyle()
  }, [])

  if (settings.debug) {
    console.log('APP level - userId:', userId, '\ncaseName:', caseName)
  }

  return (
    <Router>
      <ProgressNoir show={!userId && caseName} />
      <AuthProvider>
        <Container>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/case/:id" component={CaseIntro} />
            <Route path="/case" component={Home} />
            {/* Alpha Hide: */}
            <Route path="/about" component={About} />
            {/* Alpha Delete: */}
            {/* <Route path="/login" component={Login} />
                        <Route path="/signup" component={Signup} />
                        <Route path="/forgot-password" component={ForgotPassword} /> */}
            <PrivateRoutes />
            {/* <Route exact path="/:id"
                            render={props => (
                                <Redirect to={`case/${props.match.params.id}`} />
                            )}
                        /> */}
          </Switch>
        </Container>
      </AuthProvider>
    </Router>
  )
}

export default App
