import mainMap from './map-bw.med.jpg'
import equinoxMap from './map-equinox.jpg'
import equinoxMapPlaceholder from './map-equinox-placeholder.jpg'

const maps = {
  main: {
    name: 'Georgetown',
    map: mainMap,
    width: 350,
    buffer: 0.0005,
    haloPadding: 40,
    coords: {
      north: 47.560588,
      south: 47.527216,
      east: -122.298516,
      west: -122.345233,
    },
  },
  details: [
    {
      name: 'Equinox',
      hideNameInMap: true,
      map: equinoxMap,
      placeholder: equinoxMapPlaceholder,
      width: 350,
      buffer: 0.0005,
      haloPadding: 0.0003,
      coords: {
        north: 47.545675,
        south: 47.543672,
        east: -122.327213,
        west: -122.329948,
      },
    },
  ],
}

export default maps
