import React, { useEffect, useState } from 'react'
import { useAuth } from './contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

import { getDebugActive } from '../reducers/debugSlice'
import Items from '../Items'
import {
  getRemainingPOIAndScenes,
  getCaseData,
  getPOIAndScenesWithVisitedStatus,
} from '../../utils'
import { ButtonNoir } from '../library'
import settings from '../../settings.json'
import MyLocation from '../MyLocation'

import { fetchPosts } from '../reducers/sampleSlice'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      padding: 20,
    },
    poiAndScenes: {
      margin: 0,
      padding: 0,
      '& span:first-of-type': {
        fontWeight: 600,
        width: '30%',
        minWidth: 100,
        display: 'inline-block',
      },
      '& span:nth-of-type(2)': {
        fontStyle: 'italic',
      },
      '& li.complete': {
        '& span': {
          textDecoration: 'line-through',
        },
      },
    },
  }
})

export default function Profile() {
  const { classes } = useStyles()
  const debugActive = useSelector(getDebugActive)
  const [error, setError] = useState('')
  const { currentUser, resetCaseSettings } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [poiAndScenes, setPOIAndScenes] = useState([])
  const [caseData, setCaseData] = useState()
  const [showHints, setShowHints] = useState(false)
  const [showAdminCounter, setShowAdminCounter] = useState(0)

  // start
  const dispatch = useDispatch()
  // Select the state from the Redux store
  const {
    response: postsSample,
    loading: loadingSample,
    error: errorSample,
  } = useSelector((state) => state.sample)
  const sample = useSelector((state) => state.sample)
  const testSample = () => {
    dispatch(fetchPosts()) // Dispatch the async action to fetch posts
  }
  /// end

  useEffect(() => {
    loadCaseData()
    getRemainingPOI()
  }, [])

  const loadCaseData = async () => {
    const caseData = await getCaseData()
    setCaseData(caseData)
  }

  const getRemainingPOI = async (showHints) => {
    const poiAndScenesWithStatus = await getPOIAndScenesWithVisitedStatus()
    setPOIAndScenes(
      poiAndScenesWithStatus.map((item) => {
        return {
          name: item.name,
          visited: item?.visited,
          hint: showHints ? item?.hint : null,
        }
      })
    )
  }

  async function resetCase() {
    setLoading(true)
    setError('')

    if (
      !window.confirm('Are you sure you wish to reset? You will lose all data.')
    ) {
      return
    }

    try {
      const caseReset = await resetCaseSettings({ resetLogout: false })
      if (caseReset) {
        history.push('/welcome')
      } else {
        setError('Error: Unable to reset case.')
      }
    } catch (e) {
      console.error('Error resetting case:', e)
      setError('Error: Unable to reset case.')
    }
  }

  const debugItems = () => {
    return (
      <>
        {error && <div>{error}</div>}
        <div>
          <strong>Display Name:</strong>{' '}
          {currentUser?.displayName ? currentUser.displayName : ''}
          <br />
          <strong>UID:</strong> {currentUser?.uid ? currentUser.uid : ''}
        </div>
        {/* photoUrl = user.photoURL; */}
        <div>
          <strong>Email:</strong>{' '}
          {currentUser && currentUser.email ? currentUser.email : null}
        </div>
        <Link to="/update-profile">Update Profile</Link>
        <button onClick={resetCase}>Log Out</button>
      </>
    )
  }

  const toggleHints = () => {
    console.log('xxx test api')
    testSample()

    setShowAdminCounter(showAdminCounter + 1) // keep a counter. show admin controls when this hits a certain number
    setShowHints(true)
    getRemainingPOI(true)
  }

  console.log('sample', sample)

  return (
    <div className={classes.root}>
      <h2>{caseData?.profileSettings?.title || 'Who are we?'}</h2>
      <Items />
      <h3>
        {caseData?.profileSettings?.remaingRequiredText || 'Remainig Required:'}
      </h3>
      <ul className={classes.poiAndScenes}>
        {poiAndScenes.map((item, key) => (
          <li
            key={key}
            className={item?.visited ? 'complete' : null}
            // style={{
            //   textDecoration: item?.visited ? 'line-through' : 'none',
            // }}
          >
            <span>{item.name}</span>
            <span>{showHints && item?.hint ? ` hint: ${item?.hint}` : ''}</span>
          </li>
        ))}
      </ul>

      {/* {!showHints && ( */}
      <ButtonNoir onClick={() => toggleHints()}>Show Hints</ButtonNoir>
      {/* )} */}

      {/* {settings?.developmentMode && ( */}
      {showAdminCounter >= 10 && (
        <div style={{ marginTop: 100 }}>
          <ButtonNoir noirStyle="warning" onClick={resetCase} loading={loading}>
            Reset
          </ButtonNoir>
          <MyLocation onlyShowLocationToggle={false} />
          {debugActive ? debugItems() : null}
          v0.0.13
        </div>
      )}
      {/* )} */}
    </div>
  )
}
