// src/axios.js
import axios from 'axios'

console.log('xxx rocess.env', process.env)
console.log('xxx rocess.env', process.env.REACT_APP_API)

const apiUrl = process.env.REACT_APP_API //'http://127.0.0.1:5001/capitol-noir/us-central1/api'
console.log('apiUrl', apiUrl)

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosInstance
