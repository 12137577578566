import React, { useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import App from './App'
import defaultTheme from '../theme'
import settings from '../settings.json'

// MUI TSS REF / v4->v5:
// https://mui.com/material-ui/migration/migrating-from-jss/#2-use-tss-react
// https://mui.com/material-ui/migration/v5-style-changes/
// https://mui.com/material-ui/migration/migration-v4/
// may need to update TS module: https://mui.com/material-ui/customization/theming/

// might need to add:
// <ConnectedRouter history={history}>
// <Provider store={store}>
// see equinox Root for example

export default function Root({ store, history }) {
  const [theme, setTheme] = useState(null)

  const loadTheme = async () => {
    try {
      // Dynamically import the SCSS file
      const themeFolder = settings?.themeFolder
      if (themeFolder) {
        const themeFile = await import(`../themes/${themeFolder}/index.js`)
        console.log('loaded', themeFile)
        setTheme(themeFile.default)
      } else {
        setTheme(defaultTheme)
      }
    } catch (error) {
      console.error('Error loading theme:', error)
    }
  }

  useEffect(() => {
    loadTheme()
  }, [])

  if (!theme) return null

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}
