import React from 'react'
import ReactMarkdown from 'react-markdown'
import { stringify } from 'querystring'

import { content } from '../components/types/content'
import { MediaImage, MediaVideo } from '../components/Media'
import { getImageSeriesImg } from './index'
import imageSeriesImgType from '../components/types/imageSeries'

export const genHTMLContent = (content: content, tagKey?: string) => {
  const key = tagKey ? tagKey : Math.floor(Math.random() * 10000)
  if (content.type === 'div') {
    return <></>
    return <div key={key}>TODO DIV/media</div>
  } else if (content.type === 'imageSeries') {
    // TODO: add unique key somehwere in here...
    const { images, path } = content.value
    let html = images.map(
      (img: string | imageSeriesImgType, key: React.Key) => {
        const itemImg = getImageSeriesImg({
          path,
          item: img,
        })
        if (!itemImg) {
          console.error('Error extracting image object from:', img)
          return null
        }

        const { file, placeholder, name } = itemImg

        return (
          <div key={key}>
            <MediaImage
              path={path}
              file={file}
              placeholderFile={placeholder}
              name={name}
            />
          </div>
        )
      }
    )
    return <div key={key}>{html}</div>
  } else if (Array.isArray(content.value)) {
    return content.value.map((val: any, key: number) => {
      const value = parseHTML(val)
      if (content.type !== 'imageSeries') {
        return <content.type key={key}>{value}</content.type>
      }
    })
  } else {
    const value = parseHTML(content.value)
    if (value && React.isValidElement(value)) {
      return value
    } else if (value && typeof value === 'string') {
      return <content.type key={key}>{value}</content.type>
    } else {
      console.error(
        `Non string value in content.value.\ncontent:`,
        content,
        '\nvalue:',
        value
      )
      return (
        <h2>
          <strong>Error:</strong> {content.value}
        </h2>
      )
    }
  }
}

const parseHTML = (value: string, content?: any) => {
  if (typeof value !== 'string') {
    console.error('Error: value is not a string:', content)
    return <div>Not a string</div>
  }

  // TODO: debug this:
  // if (value === 'Car Interior') {
  // debugger
  // return value
  // }

  const brHtml = value.split('<br/>')
  if (brHtml.length > 1) {
    let html: any = null
    brHtml.forEach((value, key) => {
      html = (
        <>
          {html}
          <ReactMarkdown disallowedElements={['p']} unwrapDisallowed>
            {value}
          </ReactMarkdown>
          {key < brHtml.length - 1 ? <br /> : null}
        </>
      )
    })
    return <>{html}</>
  } else {
    return value
  }
}
